import React, { useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import "../../css/Modal.scss";
import callApi from "../../util/apiCaller";
import { useSelector } from "react-redux";
import { getInvesteesOrganization } from "../../modules/Admin/AdminReducers";
import ReactTooltip from "react-tooltip";
import isNil from "lodash/isNil";
import last from "lodash/last";
import {
  isEnvProd,
  mixPanelTrack,
  removeSpecificElementFromArray,
  roundToN,
  showNotification,
  uploadDocumentInS3,
} from "../../util/utility";
import moment from "moment";
import { useEffect } from "react";
import {
  API_STATUS,
  DEAL_REJECTION_REASONS,
  DOC_SERVICE_RESPONSE,
  FLOW_ID,
  GENERIC_ERROR,
  LOAN_TYPE,
  SHOW_NOTIFICATION,
  queryTabs,
  queryTypes,
} from "../../constants/enums";
import styles from "./ManageInvestorModal.module.scss";
import { isEmpty, isFinite, isUndefined } from "lodash";
import { getEmail, getUserId, getUserName } from "../../modules/App/AppReducer";
import { Drawer } from "@material-ui/core";
import PageLoader from "../loader/PageLoader";
import ShowFiles from "../../modules/Admin/pages/CompaniesViewPage/components/CompaniesDetailPage/components/Overview/components/DealsOverview/components/ViewDocsDrawer/UWQueries/ShowFiles/ShowFiles";
import {
  MESSAGE_TYPES,
} from "../../modules/Admin/pages/CompaniesViewPage/components/CompaniesDetailPage/components/Overview/components/DealsOverview/components/ViewDocsDrawer/UWQueries/consts/consts";
import { boradCastEmail, fetchQueryMessages, sendBulkMessage } from "../../common/ApiContainer";
import useDidMountEffect from "../../customHooks/useDidMountEffect";

const interest = {
  no: "NO",
  yes: "YES",
};
const MAXSIZE = 500 * 1024 * 1024;
const FILE_SIZE=50 * 1024 * 1024
const EMAILMAXSIZE=10 * 1000 * 1024;
const EMAILERRORMESSAGE="Total file size exceeds 10 MB and will not be sent as an attachment with email"
const MAXSIZEERRORMSG="File not uploaded. Combined file size exceeds 500 MB limit"

function ManageInvestorModal({
  fetchDeals,
  dealSize,
  dealId,
  dealName,
  isInvestmentReportGenerated = false,
  downloadReport,
  totId,
  tenure,
  loanType,
  investorDealOffer
}) {
  const RECUR_RATING_VALUE_MAP = {
    AAA: 100,
    AA: 90,
    A: 80,
    BBB: 70,
    BB: 60,
    B: 50,
    CCC: 40,
    CC: 30,
    C: 20,
    Unrated: 10,
  };

  const OPENING_ = "Opening ...";
  const MANAGE_INVESTOR = "Manage Investor";

  const {
    disabledSelect,
    enabledSelect,
    placeholderText,
    normalText,
    rejectionReasonContainer,
    optionsContainer,
    singleOptionContainer,
    transformArrow,
    saveClose,
    crossBtn,
    HeaderAlignment,
    LightBlackBorder,
    SaveRowText,
    TableHeight,
    BodyTableHeight,
    UploadAll,
    UWQueriesContainer,
    Attachment,
    FileUploadAndShow,
    Placeholder,
    InputContainer,
    SubmitButton,
    uploadCTAText,
    fileErrorMsg
  } = styles;
  const investeeOrganization = useSelector(getInvesteesOrganization);
  const textareaRef = useRef();
  const inputFileRef = useRef();
  const querySubmissionBoxRef = useRef();
  const [message, setMessage] = useState("");
  const [updatingMsg,setUpdatingMsg]=useState("")
  const [initialMsg,setInitialMsg]=useState('')
  const [showModal, setShowModal] = useState(false);
  const [showPopupMessage, setShowPopupMessage] = useState(false);
  const [sendWithoutIR, setSendWithoutIR] = useState(false);
  const [selectedInvestorId, setSelectedInvestorId] = useState([]);
  const [dealWithoutOffer, setDealWithoutOffer] = useState([]);
  const [strategies, setStategies] = useState([]);
  const [initalStrategies,setInitalStrategies]=useState([])
  const [changedStrategies,setChangesStrategies]=useState([])
  const [passedInvestors,setPassedInvestors]=useState([])
  const [companyMetrics, setCompanyMetrics] = useState({});
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMsgType, setErrorMsgType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileSizeErrorMsg,setFileSizeErrorMsg]=useState('')
  const [formIsChanged, setFormIsChanged] = useState(false);
  const formSubmitted=useRef(false)
  const [docs, setDocs] = useState([]);
  const [dealAlreadyShared,setDealAlreadyShared]=useState(false)
  const [checkAllDealsStatus,setCheckAllDealsStatus]=useState(false)
  const [sendDealDisableBtn,setSendDealDisableBtn]=useState(true)
  const [buttonDisable, setButtonDisable] = useState(true);
  const [files, setFiles] = useState([]);
  const [totalUploadedFiles,setTotalUploadedFiles]=useState([])
  const [postArr, setPostArr] = useState([]);
  const [totalAllocatedAmount, setTotalAllocatedAmount] = useState(0);
  const [showRejectionReasonOptions, setShowRejectionReasonOptions] =
    useState(null);
  const [manageButtonText, setManageButtonText] = useState(MANAGE_INVESTOR);
  const [loading, setLoading] = useState(false);
  const [debtInvestorLoader,setDebtInvestorLoader]=useState(false)
  const refObj = useRef();
  const isFirstRender = useRef(true);
  const userName = useSelector((state) => getUserName(state));
  const emailAddress = useSelector(getEmail);
  const userId = useSelector(getUserId);
  const [selectedInvestors, setSelectedInvestors] = useState([]);

  useEffect(() => {
    if (sendWithoutIR) {
      sendDealToInvestor(strategies);
      setSendWithoutIR(false);
    }
    if (!isFirstRender.current) {
      let total_amount = 0;
      for (let strategy of strategies) {
        if (strategy.allocated_amount) {
          total_amount += Number(strategy.allocated_amount);
        }
      }
      setTotalAllocatedAmount(total_amount);
      handleSave(refObj.current);
    } else {
      isFirstRender.current = false;
    }
  }, [strategies]);

  useEffect(() => {
    fetchConversation();
  }, [dealId]);

  useEffect(()=>{
   if(uploadedFiles.length){
    let totalFileSize=uploadedFiles.reduce((acc,curr)=>{
      return acc=acc+curr.size
    },0)
    if(totalFileSize>=EMAILMAXSIZE){
      setFileSizeErrorMsg(EMAILERRORMESSAGE)
    }else{
      setFileSizeErrorMsg("")
    }
   }else{
    setFileSizeErrorMsg("")
   }
  },[uploadedFiles])

    useEffect(() => {
    if (!isUploading && docs.length) {
      setFiles([])
      setUploadedFiles([])
      saveMessageOrDraft({
        message,
        messageType: MESSAGE_TYPES.DRAFT,
        // conversationId: conversationData?.id,
        identifierId: dealId,
        filesToUpdate: [...docs, ...files].filter((item)=>item.docLink),
        onSuccess: fetchConversation,
      });
      handleCloseModal()
      setButtonDisable(true)
      setFileSizeErrorMsg("")
    }
  }, [docs, isUploading,files]);
  const handleClose = () => {
    setFormIsChanged(false);
    setShowModal(false);
    fetchDeals();
    fetchStrategiesDebtDeal(null,true)
    setSendDealDisableBtn(true)
    fetchConversation()
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setMessage('')
    setUploadedFiles([])
    setFiles([])
  };
  const getFileExtn = (file) => {
    if (file) {
      const splitter = file.name.split(".");
      return splitter[splitter.length - 1];
    }
    return "";
  };
  const handleFilesValidation = (files) => {
    const IMG_EXTNS = ["gif", "jpg", "jpeg", "png", "svg"];
    const EXCEL_EXTNS = [
      "csv",
      "xls",
      "xlsx",
      "xlsm",
      "xlsb",
      "xltx",
      "xltm",
      "xlt",
      "xlam",
      "xla",
      "xlw",
      "xlr",
    ];
    const acceptedExtensions = [...IMG_EXTNS, "pdf", ...EXCEL_EXTNS, "zip"];
    let message = "";
    let extensionFailure = false;
    let sizeFailure = false;
    if (files?.length) {
      for (let file of files) {
        if (
          acceptedExtensions?.length &&
          !acceptedExtensions.includes(getFileExtn(file).toLowerCase())
        ) {
          extensionFailure = true;
          message =
            "File format not supported (only accepts Image, PDF or Excel formats)";
        }
        if (file.size > FILE_SIZE) {
          sizeFailure = true;
          message = "You cannot upload files greater than 50MB";
        }
      }
    }
    return { message, sizeFailure, extensionFailure, acceptedExtensions };
  };
  const handlePlaceholderClick = () => textareaRef.current?.focus();
  const getAcceptValue = () => {
    let acceptedStr = "";
    const { acceptedExtensions } = handleFilesValidation();
    acceptedExtensions &&
      acceptedExtensions.forEach((extn, index) => {
        if (index === acceptedExtensions.length - 1) {
          acceptedStr += "." + extn;
        } else {
          acceptedStr += "." + extn + ",";
        }
      });
    return acceptedStr;
  };
  const onAttchmentIconClick = () => {
    inputFileRef.current?.click();
  };
  const onDeleteSuccess = async (messageType, filesToUpdate) => {
    setIsDeleting(true);
    await saveMessageOrDraft({
      message,
      messageType,
      // conversationId: conversationData?.id,
      identifierId: dealId,
      filesToUpdate,
      onSuccess: fetchConversation,
    });
  };
  const onSubmit = async () => {
    formSubmitted.current=true
    if(uploadedFiles.length){
      handleSubmission(uploadedFiles)
    }
    else{
    await saveMessageOrDraft({
      message,
      messageType: MESSAGE_TYPES.DRAFT,
      identifierId: dealId,
      filesToUpdate: files,
      onSuccess: fetchConversation,
    });
    handleCloseModal()
    setButtonDisable(true)
    setIsDeleting(false);
    }

  };

  const fetchConversation = async (modalOpen) => {
    if (!dealId) {
      return;
    }
    let queryType = queryTypes.UW_QUERIES;
    const res = await fetchQueryMessages(dealId, queryType);
    if (res?.data?.responseData?.responseCode === 20) {
      const data = res.data.conversationData;
      // setConversationData(data);
      const { draftMessages } = data ?? {
        draftMessages: [],
      };
      if (draftMessages.length) {
        const { message: draftMessage, additionalDocuments } =
          draftMessages[0] ?? {
            message: "",
            additionalDocuments: [],
          };
        setMessage(draftMessage);
        setInitialMsg(draftMessage)
        setFiles(additionalDocuments);
        setTotalUploadedFiles(additionalDocuments)
      } else if (draftMessages) {
        if (message) setMessage("");
        if(initialMsg) setInitialMsg("")
        if (files?.length) setFiles([]);
        if(totalUploadedFiles?.length) setTotalUploadedFiles([])
      }
      setDebtInvestorLoader(false)
      if(modalOpen){
        setModalOpen(true);
      }
    } else {
      const message = res?.data?.responseData?.responseMessage ?? GENERIC_ERROR;
      showNotification(SHOW_NOTIFICATION.ERROR, message);
      setDebtInvestorLoader(false)
    }
  };

  const saveMessageOrDraft = async ({
    message,
    messageType,
    conversationId,
    identifierId,
    filesToUpdate,
    onSuccess,
  }) => {
    const data = {
      identifierId: identifierId,
      type: "Query",
      status: "resolved",
      senderId: userId,
      senderName: userName,
      messageType,
      senderType: "Admin",
      message,
      additionalDocuments: filesToUpdate ?? [],
      messageQueryType: queryTypes.UW_QUERIES,
    };
    setDebtInvestorLoader(true);
    setDocs([]);
    if (conversationId) data.id = conversationId;
    const res = await callApi(
      "conversation/send-message",
      "post",
      data,
      {},
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true
    );
    if (res?.responseData?.responseCode === 20) {
      if (onSuccess) onSuccess();
    } else {
      const message = res?.responseData?.responseMessage ?? GENERIC_ERROR;
      showNotification(SHOW_NOTIFICATION.ERROR, message);
      setDebtInvestorLoader(false);
    }
  };

  const sumOfOffers = useMemo(() => {
    let sum = 0;
    if (!isEmpty(strategies))
      strategies.forEach((strategy, idx) => {
        if (strategy?.offered_amount) sum += strategy.offered_amount;
      });
    return Number(sum.toFixed(2));
  }, [strategies]);

  useEffect(() => {
    for (const postObj of postArr)
      if (postObj.investor_interested === false && !postObj.rejection_reason)
        return;

    if (errorMsgType === "rejectionReasonNotSelected") {
      setShowErrorMessage(false);
    }
  }, [strategies]);

  useEffect(()=>{
    handleDisableBtn()
  },[initialMsg,updatingMsg,message,uploadedFiles,files,isDeleting,fileSizeErrorMsg])

  useEffect(()=>{
if(strategies.length){
  let _dealAlreadyShared=false
  let _checkAllDealsStatus=false
 for(let i=0;i<strategies.length;i++){
      if(strategies[i]?.checked && strategies[i]?.offer_status!==undefined && strategies[i]?.offer_status!=="Not Sent" ){
        _dealAlreadyShared=true
        _checkAllDealsStatus=true
          break ;
      }
  }
   for(let i=0;i<strategies.length;i++){
      if(strategies[i]?.checked && ( strategies[i]?.offer_status===undefined || strategies[i]?.offer_status==="Not Sent")){
        _dealAlreadyShared=false
          break ;
      }
  }
  setDealAlreadyShared(_dealAlreadyShared);
  setCheckAllDealsStatus(_checkAllDealsStatus)
}
  },[strategies])

  useEffect(()=>{
    for(let i=0;i<strategies.length;i++){
      for( let j=0;j<initalStrategies.length;j++){
        if(strategies[i]._id===initalStrategies[j]?._id ){
          if(strategies[i]?.checked){
            if(strategies[i].investor_interested !==initalStrategies[j].investor_interested || strategies[i].investment_report?.length !==initalStrategies[j].investment_report?.length || strategies[i].allocated_amount !==initalStrategies[j].allocated_amount ||strategies[i].offered_amount !==initalStrategies[j].offered_amount||strategies[i].rejection_reason?.length !==initalStrategies[j].rejection_reason?.length){
              setChangesStrategies((prev) => {
                // Check if the array already includes an object with the same _id
                if (prev.some((strategy) => strategy._id === strategies[i]._id)) {
                  return prev;
                }
                // If not included, concatenate the new object
                return [...prev, strategies[i]];
              });
              if(strategies[i].offer_status==="Passed"){
                setPassedInvestors((prev) => {
                  const _changesStategies = [...prev];
                  // Find the index of the element with the same _id
                  const alreadyHaveIndex = _changesStategies.findIndex((strategy) => strategy._id === strategies[i]._id);
                  // If found, remove it
                  if (alreadyHaveIndex !== -1) {
                    _changesStategies.splice(alreadyHaveIndex, 1);
                  }
                  return _changesStategies;
                })
              }
            }else{
              setChangesStrategies((prev) => {
                const _changesStategies = [...prev];
                // Find the index of the element with the same _id
                const alreadyHaveIndex = _changesStategies.findIndex((strategy) => strategy._id === strategies[i]._id);
                // If found, remove it
                if (alreadyHaveIndex !== -1) {
                  _changesStategies.splice(alreadyHaveIndex, 1);
                }
                return _changesStategies;
              });
              if(strategies[i].offer_status==="Passed"){
                   setPassedInvestors((prev) => {
                  // Check if the array already includes an object with the same _id
                  if (prev.some((strategy) => strategy._id === strategies[i]._id)) {
                    return prev;
                  }
                  // If not included, concatenate the new object
                  return [...prev, strategies[i]];
                })
              }
              
            }
          }else{
            setChangesStrategies((prev) => {
              const _changesStategies = [...prev];
              // Find the index of the element with the same _id
              const alreadyHaveIndex = _changesStategies.findIndex((strategy) => strategy._id === strategies[i]._id);
              // If found, remove it
              if (alreadyHaveIndex !== -1) {
                _changesStategies.splice(alreadyHaveIndex, 1);
              }
              return _changesStategies;
            });
            if(strategies[i].offer_status==="Passed"){
              setPassedInvestors((prev) => {
                const _changesStategies = [...prev];
                // Find the index of the element with the same _id
                const alreadyHaveIndex = _changesStategies.findIndex((strategy) => strategy._id === strategies[i]._id);
                // If found, remove it
                if (alreadyHaveIndex !== -1) {
                  _changesStategies.splice(alreadyHaveIndex, 1);
                }
                return _changesStategies;
              })
            }
          }
          }
      }
    }
  },[strategies,initalStrategies])

  useEffect(()=>{
    const checkedStrategies=strategies.filter((strategie)=>strategie.checked)
    if(!dealAlreadyShared){
      setSendDealDisableBtn(false)
      return ;
    } 
    if(changedStrategies.length){
      setSendDealDisableBtn(false)
      return;
    }

    if(checkedStrategies.length===passedInvestors.length){
      setSendDealDisableBtn(true)
      return
    }
    if(dealAlreadyShared && (initialMsg.length || files.length)){
      setSendDealDisableBtn(false)
      return ;
    }
      setSendDealDisableBtn(true)
    
  },[initialMsg,files,dealAlreadyShared,changedStrategies,passedInvestors,strategies])

  const handleShow = () => {
    setManageButtonText(OPENING_);
    if (loanType && loanType === "Debt Syndication") {
      fetchStrategiesDebtDeal(() => {
        setShowModal(true);
        setManageButtonText("Manage Investor");
      });
      fetchConversation()
    } else {
      fetchOffers(() => {
        setShowModal(true);
        setManageButtonText("Manage Investor");
      });
    }
  };

  useEffect(() => {
    if (showModal === false) {
      let temp_strat = [...strategies];
      for (let s of temp_strat) if (s.checked) s.checked = false;
      setStategies(temp_strat);
      setShowRejectionReasonOptions(null);
    }
  }, [showModal]);

  useEffect(()=>{
    if(investeeOrganization && dealId)fetchStrategiesDebtDeal(null,true)
  },[investeeOrganization,dealId])

  const fetchOffers = (cb) => {
    callApi("admin/autoInvest/get_strategies/match_percentage", "post", {
      investee_organization_id: investeeOrganization?._id,
      deal_id: dealId,
      offer_amount: { dealSize },
    }).then((res) => {
      if (res.status === "Success") {
        cb && cb();
        // Assuming res.data?.final_strategies is the original array
        const updatedStrategies = res.data?.final_strategies.map(strategy => {
          // Check if investor_organization_id is present in selectedInvestors
          const isChecked = selectedInvestors.includes(strategy.investor_organization_id);
        
          // Add a checked property to the strategy
          return { ...strategy, checked: isChecked };
        });
        // Update the state with the modified array
        setStategies(updatedStrategies);
        setInitalStrategies(updatedStrategies)
        setSelectedInvestors([])
        setCompanyMetrics(res.data?.company_metrics);
        setShowErrorMessage(false);
      } else {
        setManageButtonText(MANAGE_INVESTOR);
        const message = res?.error ?? "Some error occured";
        showNotification("Error", message);
      }
    });
  };

  const fetchStrategiesDebtDeal = (cb, updatingInitialStrategies) => {
    callApi("fetch-match-percentage-debt-deal", "post", {
      orgId: investeeOrganization?._id,
      dealId: dealId,
    }).then((res) => {
      if (res.status === API_STATUS.SUCCESS) {
        cb && cb();
        let _strategies=[]
        if(strategies.length){
          for(let i=0;i<res.data?.investorStrategies.length;i++){
            for(let j=0;j<strategies.length;j++){
              if(strategies[j]?._id===res.data?.investorStrategies[i]?._id){
                let payload={
                  ...res.data?.investorStrategies[i],
                }
                if(strategies[j]?.checked !==undefined){
                  payload.checked=strategies[j]?.checked
                }
                _strategies.push(payload)
              }
            }
          }
          setStategies(_strategies);
        }else{
          setStategies([...res.data?.investorStrategies])
        }

       if(updatingInitialStrategies) setInitalStrategies([...res.data?.investorStrategies])
        setCompanyMetrics(res.data?.companyMetrics);
        setShowErrorMessage(false);
      } else {
        setManageButtonText(MANAGE_INVESTOR);
        const message =
          res?.data?.message ?? res?.error?.message ?? "Some error occured";
        showNotification(SHOW_NOTIFICATION.ERROR, message);
      }
    });
  };

  const saveModal = () => {
    if (dealSize !== totalAllocatedAmount) {
      showNotification(
        "Error",
        "Total allocated amount should be equal to deal size"
      );
      return;
    }
    const saveModalArr = {
      deal_offer: [...postArr],
    };
    for (const postObj of postArr) {
      if (
        postObj.investor_interested === false &&
        (!postObj.rejection_reason || postObj.rejection_reason.length <= 0)
      ) {
        setShowErrorMessage(true);
        setErrorMsgType("rejectionReasonNotSelected");
        return;
      }
    }
    callApi("admin/manage-investor-deal-update", "post", saveModalArr).then(
      (res) => {
        if (res.status === API_STATUS.SUCCESS) {
          showNotification("success", "Modal Saved Successfully");
        } else {
          const message = res?.data?.message ?? "Some error occurred!";
          showNotification("Error", message);
        }
      }
    );
    setPostArr([]);
    handleClose();
  };

  const handleSave = (obj) => {
    if (obj?.offer_id) {
      const sameOfferIdIndex = postArr.findIndex(
        (investor) => investor.offer_id === obj.offer_id
      );
      if (sameOfferIdIndex !== -1) {
        const temp_arr = [...postArr];
        temp_arr[sameOfferIdIndex].investor_interested =
          obj.investor_interested;
        temp_arr[sameOfferIdIndex].allocated_amount = obj.allocated_amount;
        temp_arr[sameOfferIdIndex].offered_amount = obj.offered_amount;
        temp_arr[sameOfferIdIndex].rejection_reason = obj.rejection_reason;
        temp_arr[sameOfferIdIndex].investor_deal_id = obj.investor_deal_id;
        setPostArr(temp_arr);
      } else {
        const investor_obj = {
          offer_id: obj.offer_id,
          offered_amount: obj.offered_amount,
          allocated_amount: obj.allocated_amount,
          investor_interested: obj.investor_interested,
          rejection_reason: obj.rejection_reason,
          investor_deal_id: obj.investor_deal_id,
        };
        setPostArr((prevArr) => {
          const temp_arr = [...prevArr];
          temp_arr.push(investor_obj);
          return temp_arr;
        });
      }
    }
  };

  const handleChange = (value, index, type) => {
    if (Math.sign(Number(value)) === -1) {
      setShowErrorMessage(true);
      setErrorMsgType("negativeValue");
      return;
    }
    if (type === "allocated_amount") {
      if (strategies[index]?.offered_amount < value) {
        setShowErrorMessage(true);
        setErrorMsgType("allocationGreaterThanOffer");
        return;
      }
      const allocatedAmount = strategies[index].allocated_amount;
      if (allocatedAmount) {
        if (totalAllocatedAmount - allocatedAmount + Number(value) > dealSize) {
          setShowErrorMessage(true);
          setErrorMsgType("allocationAmountChanges");
          return;
        }
      }
      if (!allocatedAmount) {
        if (totalAllocatedAmount + Number(value) > dealSize) {
          setErrorMsgType("allocationAmountChanges");
          return;
        }
      }
    }
    setFormIsChanged(true);
    setStategies((prevStrategies) => {
      const arr = [...prevStrategies];
      if (type === "offered_amount") {
        arr[index].offered_amount = value ? Number(value) : "";
        if (!arr[index].initialMatchScore)
          arr[index].initialMatchScore = arr[index].matchScore;
        const isInvestAmtInMatchScoreIncremented =
          companyMetrics.investmentAmount >= arr[index].min_investment &&
          companyMetrics.investmentAmount <= arr[index].max_investment;
        const newMatchScore =
          value / 100 >= arr[index].min_investment &&
          value / 100 <= arr[index].max_investment
            ? isInvestAmtInMatchScoreIncremented
              ? arr[index].initialMatchScore
              : arr[index].initialMatchScore + 1
            : !isInvestAmtInMatchScoreIncremented
            ? arr[index].initialMatchScore
            : arr[index].initialMatchScore - 1;
        arr[index].matchScore = newMatchScore;
        refObj.current = arr[index];
      } else {
        arr[index].allocated_amount = value ? Number(value) : "";
        refObj.current = arr[index];
      }

      return arr;
    });
    setShowErrorMessage(false);
  };

  const handleShareDealClick = () => {
    let filteredStrategies = strategies.filter((s) => s.checked === true);
    setCheckedInvestor(filteredStrategies);
    let _strategies = [];
    if (!isEmpty(filteredStrategies))
      filteredStrategies.forEach((strategy) => {
        if (!isNil(strategy.offered_amount)) {
          _strategies.push({
            strategy_id: strategy?._id,
            investor_organization_id: strategy?.investor_organization_id,
            offered_amount: strategy.offered_amount,
            name: strategy?.name,
          });
        }
      });

    let obj = {
      deal_id: dealId,
      investee_organization_id: investeeOrganization?._id,
      deal_size: dealSize,
      strategies: _strategies,
      loanType:
        loanType && loanType === "Debt Syndication"
          ? LOAN_TYPE.DEBT_SYNDICATION
          : "",
    };
    callApi(
      "autoInvest/create_offer/generate_investment_report",
      "post",
      obj
    ).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Investment report generation triggered");
        fetchDeals();
        handleClose();
      } else {
        const message = res.error ?? "Some error occurred!";
        showNotification("Error", message);
      }
    });
  };

  const handleRegenerateClick = () => {
    let filteredStrategies = strategies.filter((s) => s.checked === true);
    setCheckedInvestor(filteredStrategies);
    let _offers = [];
    if (!isEmpty(filteredStrategies))
      filteredStrategies.forEach((offer) => {
        if (!isNil(offer.offered_amount))
          _offers.push({
            _id: offer?.offer_id,
            investor_organization_id: offer?.investor_organization_id,
            offered_amount: offer.offered_amount,
            strategy_id: offer?._id,
          });
      });

    let obj = {
      deal_id: dealId,
      deal_size: dealSize,
      investee_organization_id: investeeOrganization?._id,
      offers: _offers,
      loanType:
        loanType && loanType === "Debt Syndication"
          ? LOAN_TYPE.DEBT_SYNDICATION
          : null,
    };
    callApi(
      "deals/admin/re_generate_investment_report/investment_report",
      "post",
      obj
    ).then((res) => {
      if (res.status === "Success") {
        showNotification(
          "success",
          "Investment report re-generated Successfully"
        );
        fetchDeals();
        if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION) {
          fetchStrategiesDebtDeal();
        } else fetchOffers();
        setFormIsChanged(false);
        handleClose();
      } else {
        const message = res.error ?? "Some error occurred!";
        showNotification("Error", message);
      }
    });
  };

  const setCheckedInvestor = (filteredStrategies) => {
    let investorOrgId = [];
    filteredStrategies.forEach((element) =>
      investorOrgId.push(element?.investor_organization_id)
    );
    setSelectedInvestorId(investorOrgId);
  };

  const deleteInvestmentReport = (offer) => {
    let obj = {
      deal_id: dealId,
      deal_size: dealSize,
      investee_organization_id: investeeOrganization?._id,
      offers: [{ ...offer, _id: offer.offer_id }],
    };
    callApi(
      "deals/admin/delete_investment_report/investment_report",
      "post",
      obj
    ).then((res) => {
      if (res.status === "Success") {
        showNotification("success", "Investment report deleted Successfully");
        if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION)
          fetchStrategiesDebtDeal();
        else fetchOffers();
      } else {
        const message = res.error ?? "Some error occurred!";
        showNotification("Error", message);
      }
    });
  };

  const handleOpenModal = () => {
    fetchConversation(true)
  };

  const onFileUWChangeCapture = (e) => {
    const filesArr = [...e.target.files];
    const { message, sizeFailure, extensionFailure, acceptedExtensions } =
      handleFilesValidation(filesArr);
    let filteredFiles = [...filesArr];
    if (extensionFailure) {
      showNotification(SHOW_NOTIFICATION.ERROR, message);
      filteredFiles = filesArr.filter((file) => {
        return acceptedExtensions?.includes(getFileExtn(file));
      });
    }
    if (sizeFailure) {
      showNotification(SHOW_NOTIFICATION.ERROR, message);
      filteredFiles = filesArr.filter((file) => file.size < FILE_SIZE);
      setButtonDisable(true)
    }else{
      setButtonDisable(false)
    }
    if (filteredFiles?.length) {
      let totalFileSize=filteredFiles.reduce((acc,curr)=>acc+=curr.size,0)
      if(totalFileSize>=EMAILMAXSIZE){
        setFileSizeErrorMsg(EMAILERRORMESSAGE)
      }
      setUploadedFiles((prev)=>prev.concat(filteredFiles));
      let _files=filteredFiles.map((item)=>{
        return{
          docName:item.name,
          docLink:""
        }
      })
      setFiles((prev)=>prev.concat(_files))
    }
  };
  const handleSubmission = async (files) => {
    // API call to upload file
    setIsUploading(true);
    let index = 0;
    for (let file of files) {
      await uploadDocument({ file, isLastFile: index === files.length - 1 });
      index++;
    }
    setIsUploading(false);
  };
  const getFileNameFromSignedUrl = (preSignedUrl) =>
    decodeURIComponent(preSignedUrl)?.split("uw_queries/")[1]?.split("?")[0];

  const uploadDocument = async ({ file, isLastFile }) => {
    try {
      const data = { url: "", fileName: "" };
      const signResponse = await callApi(
        "file/signedUrl",
        "post",
        {
          categoryId: Number(process.env.REACT_APP_UW_QUERIES_CATEGORY_ID),
          fileName: file.name,
          // investeeOrgName: deal.name,
        },
        {},
        false,
        false,
        false,
        false,
        false,
        true
      );
      if (
        signResponse?.responseData?.responseCode !==
        DOC_SERVICE_RESPONSE.SUCCESS
      ) {
        const message =
          signResponse?.responseData?.responseMessage ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION.ERROR, message);
        return;
      }
      data.url = signResponse.signedUrl;
      const requestOptions = {
        method: "put",
        body: file,
        headers: new Headers({
          "Content-Type": file.type,
        }),
      };
      if (data.url) {
        data.fileName = getFileNameFromSignedUrl(data.url);
        const uploadResponse = await fetch(data.url, requestOptions);
        if (uploadResponse.ok) {
          const input = {
            categoryId: Number(process.env.REACT_APP_UW_QUERIES_CATEGORY_ID),
            fileName: data.fileName,
            fileSizeKb: file.size / 1_000,
            fileType: file.type,
            uploadedBy: userName,
            emailAddress,
            flowId: FLOW_ID.UW_QUERIES,
          };
          const updateResponse = await callApi(
            "file/saveMetadata",
            "post",
            input,
            {},
            false,
            false,
            false,
            false,
            false,
            true
          );
          if (
            updateResponse?.responseData?.responseCode ===
            DOC_SERVICE_RESPONSE.SUCCESS
          ) {
            const { fileMetadata } = updateResponse;
            const { id: docId } = fileMetadata ?? {
              id: null,
            };
            if (docId) {
              // saving docId in state in order to send in saveDraft API
              const tempDocs = [];
              tempDocs.push({ docLink: docId, docName: data.fileName });
              setDocs((prev)=>prev.concat(tempDocs));
            } else
              throw new Error(
                "Didn't get docId in response of save meta data of doc service API"
              );
          } else {
            const message =
              updateResponse?.responseData?.responseMessage ?? GENERIC_ERROR;
            showNotification(SHOW_NOTIFICATION.ERROR, message);
          }
        } else throw new Error(uploadResponse);
      } else throw new Error("Couldn't receive signed url!");
    } catch (error) {
      showNotification(
        SHOW_NOTIFICATION.ERROR,
        "Failed to upload, something went wrong"
      );
    } finally {
      if (isLastFile) {
        if (inputFileRef.current) inputFileRef.current.value = "";
      }
    }
  };
  const onFileChangeCapture = (e, offer = {}, key, toPassLoanType = false) => {
    if (isEmpty(offer)) {
      for (let strategy of strategies) {
        if (strategy.offer_status) {
          offer = strategy;
          break;
        }
      }
    }
    const file = e.target.files[0];
    const splitter = file.name.split(".");
    const input = {
      fileName: `${offer?.offer_id}_${moment().format("yyyyMMDDHHmmss")}_${
        file.name
      }`,
      file_type: file.type,
      extension: splitter[splitter.length - 1],
      offer_id: offer?.offer_id,
    };
    const metaDataInput = {
      deal_id: dealId,
      deal_size: dealSize,
      investee_organization_id: investeeOrganization?._id,
      offers: [{ ...offer, _id: offer.offer_id }],
    };
    if (toPassLoanType) {
      metaDataInput.loanType = loanType;
    }
    const onUploadSuccess = () => {
      if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION)
        fetchStrategiesDebtDeal();
      else fetchOffers();
    };
    const offerId = offer.offer_id;
    uploadDocumentInS3(
      "admin/deals/deal_documents/s3/sign",
      input,
      file,
      "deals/admin/upload_investment_report/investment_report",
      metaDataInput,
      key,
      onUploadSuccess,
      offerId
    );
  };

  const handlePopupMessage = () => {
    setShowPopupMessage(!showPopupMessage);
  };

  const checkOfferCreated = async () => {
    let filteredStrategies = strategies.filter((s) => s.checked === true);
    setCheckedInvestor(filteredStrategies);
    let _strategies = [];
    if (!isEmpty(filteredStrategies))
      filteredStrategies.forEach((strategy) => {
        if (
          (!isNil(strategy.offered_amount) && isNil(strategy.offer_id)) ||
         isNil(strategy.investment_report)
        ) {
          
          _strategies.push({
            strategy_id: strategy?._id,
            investor_organization_id: strategy?.investor_organization_id,
            offered_amount: strategy.offered_amount,
            skip_investment_report: true,
            name: strategy?.name,
          });
        }
      });
    if (!isEmpty(_strategies)) {
      setDealWithoutOffer(_strategies);
      if(checkAllDealsStatus &&  loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION){
        sendDealToInvestorWithoutIR(_strategies)
      } else {
        handlePopupMessage();
      }
    } else {
      sendDealToInvestor(filteredStrategies);
    }
  };

  const sendDealToInvestorWithoutIR = async ( strategies) => {
    if(showPopupMessage)handlePopupMessage();
    let obj = {
      deal_id: dealId,
      investee_organization_id: investeeOrganization?._id,
      deal_size: dealSize,
      strategies: isEmpty(dealWithoutOffer) ? strategies : dealWithoutOffer,
      loanType:
        loanType && loanType === "Debt Syndication"
          ? LOAN_TYPE.DEBT_SYNDICATION
          : "",
      isInvestmentReportNotGenerated:true
    };
    const res = await callApi(
      "autoInvest/create_offer/generate_investment_report",
      "post",
      obj
    );
    if (res.status === "Success") {
      fetchDeals();
      setSendWithoutIR(true);
      if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION)
        fetchStrategiesDebtDeal();
      else fetchOffers();
      return;
    } else {
      const message = res.error ?? "Some error occurred!";
      showNotification("Error", message);
      return;
    }
  };


const sendBulkMessageCallback=async(identifierIds)=>{
  try{
    if(initialMsg.length || files.length ){
      const payload = {
        identifierIds: identifierIds,
        id: dealId,
        type: "Query",
        status: "resolved",
        senderId: userId,
        senderName: userName,
        messageType: "chat",
        senderType: "Admin",
        message: initialMsg,
        additionalDocuments: files,
        messageQueryType: "uw_queries",
      };
       await sendBulkMessage(payload);
    }
  }catch(err){
      console.log(err)
  }
}

const sendBroastMailCallback =async(brodcastOfferIds)=>{
  try{
    const payload={
      offerIds:brodcastOfferIds,
      investeeOrgId:investeeOrganization?._id,
      message:initialMsg,
      additionalDocuments:files,
      dealOfferDoc:investorDealOffer.filter((item) => {
        return item.investment_report?.length;
      }).map((item)=>{
        const splitedInvestmentName=item.investment_report.split('/')
        return {
          investorOrganizationId:item.investor_organization_id,
          docLink:splitedInvestmentName[3]+'/' +splitedInvestmentName[4],
          docName:splitedInvestmentName[4]
        }
      })
    }
    await boradCastEmail(payload)
  }catch(err){
    console.log(err)
  }finally{
    setLoading(false);
    handleClose();
  }
}

  const sendDealToInvestor = async (data) => {
    if (
      investeeOrganization?.recur_rating === "Unrated" &&
      (!loanType || loanType !== LOAN_TYPE.DEBT_SYNDICATION)
    ) {
      showNotification(
        "error",
        `Please provide rating for the company before sending deal`
      );
      return;
    }
    setLoading(true);
    let filteredStrategies = [];
    filteredStrategies = data.filter((s) => s.checked === true)
    let _offers = [];
    let isValid = true;
    if (!isEmpty(filteredStrategies))
    filteredStrategies.forEach((offer) => {
      if (!isNil(offer?.offered_amount) && offer?.offered_amount !== "") {
        if (Number(offer.offered_amount) <= 0) {
          showNotification(
            "error",
            `Offer amount cannot be zero or negative for ${offer?.investor_organization_name}`
          );
          isValid = false;
          return;
        }
          _offers.push({
            ...offer,
            _id: offer?.offer_id,
          });
      }
    });

      const offerIds = _offers
        .filter((item) => {
          let haveAlreadyExitsOffer=changedStrategies.some((changedStrategie)=>changedStrategie.offer_id===item.offer_id)
          if(haveAlreadyExitsOffer &&  item.investor_organization_id?.length){
            return item
          } 
          if( item.offer_status !== "Passed" && item.offer_status !== "Approved" &&
          item.investor_organization_id?.length)
          return item;
        })
        .map((offer) => offer._id);
          if (!isValid) {
            setLoading(false);
            return;
          }
if (_offers.length) {
  let obj = {
    deal_id: dealId,
    tot_id: totId,
    deal_size: dealSize,
    investee_organization_id: investeeOrganization?._id,
    investee_org_name: investeeOrganization?.name,
    offers: _offers,
  };
  callApi("/deals/admin/send_offers_to_investors", "post", obj).then(
    async (res) => {
      setLoading(false);
      if (res.status === "Success") {
        showNotification("success", "Deals sent to Investors Successfully");
        if (isEnvProd) {
          filteredStrategies.forEach((offer) => {
            mixPanelTrack({
              id: offer?.investor_organization_id,
              trackUserObj: {
                clicked_by: userName,
                offer_amount: offer?.offered_amount,
                deal_name: dealName,
                deal_id: dealId,
                investor_name: offer?.investor_organization_name,
                allocated_amount: offer?.allocatedAmount,
                tenure: tenure,
                recur_grade: investeeOrganization?.recur_rating,
              },
              trackCategoryName: "Send Deal To Investor",
            });
          });
        }
        handleClose();
        if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION) {
          fetchStrategiesDebtDeal();
          if (offerIds.length) await sendBulkMessageCallback(offerIds);
        } else fetchOffers();
      } else {
        if (
          loanType &&
          loanType === LOAN_TYPE.DEBT_SYNDICATION &&
          offerIds.length
        ) {
          await sendBulkMessageCallback(offerIds);
        }
        const message = res.error ?? res.data.message ?? GENERIC_ERROR;
        showNotification(SHOW_NOTIFICATION.ERROR, message);
      }
    }
  );
} else {
  setLoading(false);
}
  };

  /************ Calculate Match Percentage for a Strategy  **************/
  const calculateMatchPercentageForAStrategy = (
    strategy_details,
    company_metrics
  ) => {
    const TOTAL_MATCHING_PARAMETERS = 8;
    let score = 0;
    if (
      Number(company_metrics?.recur_rating) >=
      Number(strategy_details?.recur_rating)
    )
      score += 1;
    if (strategy_details?.offered_amount) {
      if (
        Number(strategy_details?.offered_amount) <=
        strategy_details?.max_investment
      )
        score += 1;
    }
    if (company_metrics?.min_arr >= strategy_details?.min_arr) score += 1;
    if (company_metrics?.min_arr_growth >= strategy_details?.min_arr_growth)
      score += 1;
    if (strategy_details?.age >= strategy_details?.min_age) score += 1;
    if (company_metrics?.min_runway >= strategy_details?.min_runway) score += 1;
    if (
      company_metrics?.max_customer_churn <=
      strategy_details?.max_customer_churn
    )
      score += 1;
    if (
      company_metrics?.max_revenue_churn <= strategy_details?.max_revenue_churn
    )
      score += 1;

    const matching_percentage = (score / TOTAL_MATCHING_PARAMETERS) * 100;
    return matching_percentage;
  };

  function generateMatchScoreForDebtSyndicationDealForInvestorStrategy({
    matchScore,
    totalCount,
    incrementBy = 0,
  }) {
    const matchPercentage = roundToN(
      ((matchScore + incrementBy) / totalCount) * 100,
      2
    );
    return isFinite(matchPercentage) ? matchPercentage : 0;
  }

  const setColourWithBoolean = (val) => { 
    return val ? "w-50 Match-bg-green" : "w-50 Match-bg-red"
  };


  const setColorGreaterThanOrEqualCheck = (lVal, rVal) => {
    if (Number(lVal) >= Number(rVal)) {
      return "w-50 Match-bg-green";
    } else return "w-50 Match-bg-red";
  };

  const setColorGreaterThanCheck = (lVal, rVal) => {
    if (Number(lVal) > Number(rVal)) {
      return "w-50 Match-bg-green";
    } else return "w-50 Match-bg-red";
  };

  const setColorLessThanOrEqualCheck = (lVal, rVal) => {
    if(isNil(lVal) || isNil(rVal) )return "w-50 Match-bg-red"
    if (Number(lVal) <= Number(rVal)) {
      return "w-50 Match-bg-green";
    } else return "w-50 Match-bg-red";
  };
  const getMatchColor = (percentage) => {
    if (percentage >= 70) return "Match-bg-green";
    if (percentage > 25) return "Match-bg-orange";
    else return "Match-bg-red";
  };

  const getValueWithPercentage = (val) => {
    if (isNil(val)) return "_";
    else return `${val}%`;
  };

  const handleChecked = (e, strategy) => {
    const alreadySelectedInvestorOrgId = {};
    // Check if investor_organization_id is already in selectedInvestors
    const index = selectedInvestors.indexOf(strategy.investor_organization_id);  
    if (index === -1) {
      // If not present, add it to the array
      setSelectedInvestors([...selectedInvestors, strategy.investor_organization_id]);
    } else {
      // If present, remove it from the array
      const updatedInvestors = [...selectedInvestors];
      updatedInvestors.splice(index, 1);
      setSelectedInvestors(updatedInvestors);
    }

    strategies.map((s) => {
      if (s?.checked && s.investor_organization_id)
        alreadySelectedInvestorOrgId[s.investor_organization_id] = s.name;
    });
    const updateStategies= (prevStrategies) =>{
      return prevStrategies.map((s) => {
         if (loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION) {
           if (s._id === strategy._id && s?.investor_organization_id) {
             if (
               s.investor_organization_id in alreadySelectedInvestorOrgId &&
               s.checked
             ) {
               return { ...s, checked: false };
             } else if (
               s.investor_organization_id in alreadySelectedInvestorOrgId &&
               !s.checked
             ) {
               showNotification(
                 SHOW_NOTIFICATION.ERROR,
                 "You can't select same investor twice"
               );
               return { ...s, checked: false };
             }
             return {
               ...s,
               checked: e.target.checked,
               offered_amount: s.offered_amount ?? dealSize,
             };
           } else return s;
         } else {
           if (s.investor_organization_id === strategy.investor_organization_id)
             return {
               ...s,
               checked: e.target.checked,
               offered_amount: s.offered_amount ?? dealSize,
             };
           else return s;
         }
       })
     }

    setStategies(updateStategies);
    setInitalStrategies(updateStategies);
  };

  const handleBtnState = (btnType) => {
    let anyStrategySelected = false;
    strategies.forEach((s) => {
      if (s.checked) anyStrategySelected = true;
      return;
    });
    if (!anyStrategySelected)
      return true; // return false if not any option selected
    else if (btnType === ("Re-Generate" || "Generate Investor" || "Send Deal"))
      return sumOfOffers <= 0;
  };

  const handleInterest = (value, index) => {
    setStategies((prevStrategies) => {
      const arr = [...prevStrategies];
      if (value === interest.no) arr[index].investor_interested = false;
      else arr[index].investor_interested = true;
      refObj.current = arr[index];
      return arr;
    });
  };

  const handleRejectionReason = (value, index) => {
    let arr = [...strategies];
    if (!("rejection_reason" in arr[index])) arr[index].rejection_reason = [];
    if (arr[index].rejection_reason.includes(value))
      arr[index].rejection_reason = removeSpecificElementFromArray({
        array: arr[index].rejection_reason,
        element: value,
      });
    else arr[index].rejection_reason.push(value);
    refObj.current = arr[index];
    setStategies(arr);
  };

  const updateShowRejectionReasonsOptions = (index) => {
    if (
      showRejectionReasonOptions === index &&
      !isNil(showRejectionReasonOptions)
    )
      setShowRejectionReasonOptions(null);
    else {
      if (strategies[index].investor_interested === false)
        setShowRejectionReasonOptions(index);
    }
  };

  const getTotalNumberOfInvestor = () => {
    const checkedInvestors = strategies.filter((item) => item?.checked);
    return checkedInvestors.length === 0 ? "" : checkedInvestors.length;
  };

  const handleDisableBtn=()=>{
    let disable=true
    if(isDeleting && files.length) {
      setButtonDisable(false)
      return
    }
    // if(formSubmitted.current){
    //   formSubmitted.current=false
    //   setButtonDisable(true)
    //   return
    // }

    // if(fileSizeErrorMsg.length && fileSizeErrorMsg===MAXSIZEERRORMSG){
    //   setButtonDisable(true)
    //   return
    // }
    if(!uploadedFiles.length){
      if (inputFileRef.current) inputFileRef.current.value = "";
    }
    // if(!files.length && !uploadedFiles.length){
    //   disable=true
    // }else if(initialMsg.length && updatingMsg.length && initialMsg!==updatingMsg){
    //   disable=false
    // }else if(!message.length && !uploadedFiles.length){
    //   disable=true
    // }else if(uploadedFiles.length && message.length ){
    //   disable=false
    // }
    // if(initialMsg.length && updatingMsg.length && initialMsg===updatingMsg){
    //   disable=true
    // }else 

    if(message.length===0 && initialMsg.length){
      setButtonDisable(false)
      return;
    }
    if(message.length || uploadedFiles.length){
      disable=false
    }
    setButtonDisable(disable)
  }

  return (
    <>
      {loading || debtInvestorLoader ? <PageLoader showOverlay /> : null}
      {dealId && (
        <button
          variant="primary"
          onClick={handleShow}
          className="border-0 mt-3 fs9 fw500 px-3 py-1 br4 app-sub"
          disabled={OPENING_ === manageButtonText}
        >
          {manageButtonText}
        </button>
      )}
      <Drawer
        anchor="right"
        open={showModal}
        onClose={handleClose}
        transitionDuration={500}
        PaperProps={{
          style: {
            width: "80%",
            padding: "10px",
          },
        }}
        disableEnforceFocus
        className="ManageInvestorModal"
      >
        <div className={`"border-0 mb-2 modal-header ${HeaderAlignment}`}>
          <div className={SaveRowText}>
            <div>Manage Investors</div>
          </div>
          <button onClick={saveModal}>
            <span className={saveClose}>Save and Close</span>
            <span className={crossBtn}>
              <img src="../../../../../assets/modal-cross.svg" alt=""></img>
            </span>
          </button>
        </div>

        {/* <div className={LightBlackBorder}></div> */}
        <div className={"pt-0" + " " + BodyTableHeight}>
          <div className="d-flex justify-content-between fs12 Deal-Name-Size">
            <div>
              <span className="DN">Deal Name:</span>
              <span> {dealName}</span>
            </div>
            <div>
              <span className="DN">Deal Size (INR lacs):</span>
              <span> {dealSize}</span>
            </div>
            <div>
              <span className="DN">Total Offer (Lacs) :</span>
              <span style={{ color: "#73C96E" }}> {sumOfOffers}</span>
            </div>
            <div>
              <span className="DN">Total Allocation (lacs) : </span>
              <span>{totalAllocatedAmount}</span>
            </div>
          </div>
          <div className={`modal-table ${TableHeight}`}>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Investor Name</th>
                  {loanType && loanType === "Debt Syndication" && <th>PEC</th>}
                  <th style={{ width: "8%" }}>Match</th>
                  <th style={{ width: "9%" }}>Inv. Report</th>
                  {isInvestmentReportGenerated && <th>Upload</th>}
                  <th style={{ width: "13%" }}>Offer (Lacs)</th>
                  <th style={{ width: "13%" }}>Allocation (Lacs)</th>
                  <th>Interested</th>
                  <th>Rejection Reason</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(strategies) &&
                  strategies?.map((strategy, index) => {
                    const MATCH_PERCENTAGE =
                      loanType && loanType === LOAN_TYPE.DEBT_SYNDICATION
                        ? generateMatchScoreForDebtSyndicationDealForInvestorStrategy(
                            {
                              matchScore: strategy?.matchScore,
                              totalCount: strategy?.totalCount,
                            }
                          )
                        : calculateMatchPercentageForAStrategy(
                            strategy,
                            companyMetrics
                          );

                    return (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={(e) => handleChecked(e, strategy)}
                            checked={strategy.checked}
                          />
                        </td>
                        <td
                          title={strategy?.investor_organization_display_name}
                          style={{
                            cursor: "pointer",
                            maxWidth: "150px",
                            width: "150px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {strategy?.investor_organization_display_name ?? strategy?.investor_organization_name ?? "_"}
                        </td>

                        {loanType && loanType === "Debt Syndication" && (
                          <td
                            title={strategy?.name}
                            style={{
                              cursor: "pointer",
                              maxWidth: "150px",
                              width: "150px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {strategy?.name ?? "_"}
                          </td>
                        )}

                        <td>
                          <div className="d-flex align-items-center">
                            <span className={getMatchColor(MATCH_PERCENTAGE)}>
                              {MATCH_PERCENTAGE}%
                            </span>
                            <span
                              data-tip=""
                              data-for={`match-percentage-${index}`}
                              style={{ height: "18px" }}
                            >
                              <img
                                src="../../../../../assets/bar-chart.svg"
                                alt="match"
                              />
                            </span>
                          </div>
                          {loanType && loanType === "Debt Syndication" ? (
                            <ReactTooltip
                              key={index}
                              id={`match-percentage-${index}`}
                              type="light"
                              place="bottom"
                              className="Match-tooltip"
                              border={true}
                              borderColor="black"
                              delayHide={500}
                              delayShow={50}
                              delayUpdate={500}
                              multiline={true}
                            >
                              <div className="dflexJA fs14 px-2 py-3">
                                <div className="w-50">Strategy Match</div>
                                <div
                                  className={`w-50 ${getMatchColor(
                                    MATCH_PERCENTAGE
                                  )}`}
                                >
                                  {MATCH_PERCENTAGE}%
                                </div>
                              </div>

                              {/* recur grade */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Recur Grade
                                {(strategy?.mandatoryPreferences?.isRecurRatingMandatory)&&
                                <span style={{color:'red'}}>*</span>}
                                </div>
                                
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesRecurGradeMatch)}>
                                    {companyMetrics?.recurGrade}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.recur_rating}
                                  </div>
                                </div>
                              </div>

                              {/* max investment */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">
                                  Max Investment
                                </div>
                                <div className="dflexJA w-50">
                                <div
                                    className={setColorLessThanOrEqualCheck(
                                      strategy?.offered_amount / 100
                                        ? strategy?.offered_amount / 100
                                        : companyMetrics.investmentAmount,
                                      strategy?.max_investment
                                    )}
                                  >
                                    {strategy?.offered_amount / 100
                                      ? strategy?.offered_amount / 100
                                      : companyMetrics.investmentAmount}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.max_investment} (in cr)
                                  </div>
                                </div>
                              </div>

                              {/* min investment */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">
                                  Min Investment {(strategy?.mandatoryPreferences?.isInvestmentMandatory)&&
                                <span style={{color:'red'}}>*</span>}
                                </div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesInvestmentMatch)}>
                                    {strategy?.offered_amount / 100
                                      ? strategy?.offered_amount / 100
                                      : companyMetrics.investmentAmount}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_investment} (in cr)
                                  </div>
                                </div>
                              </div>

                              {/* Annual Revenue */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">
                                  Annual Revenue
                                  {(strategy?.mandatoryPreferences?.isAnnualRevenueMandatory)&&
                                <span style={{color:'red'}}>*</span>}
                                </div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesAnnualRevenueMatch)}>
                                    {companyMetrics?.annualRevenue}
                                  </div>
                                  <div className="w-50 clr16">
                                    /{" "}
                                    {Number(
                                      strategy?.min_annual_revenue
                                    ).toFixed(2)}{" "}
                                    (in cr)
                                  </div>
                                </div>
                              </div>

                              {/* Ebitda Margin */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Ebitda Margin
                                {(strategy?.mandatoryPreferences?.isEbidtaNegativeMandatory)&&
                                <span style={{color:'red'}}>*</span>}
                                </div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesEbitdaMatch)}>
                                    {companyMetrics?.ebitdaMargin}
                                  </div>
                                  <div className="w-50 clr16">
                                    /{" "}
                                    {strategy?.ebidta_negative
                                      ? "Accepts -ve"
                                      : "Cannot be -ve/0"}
                                  </div>
                                </div>
                              </div>

                              {/* Age */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Age
                                {(strategy?.mandatoryPreferences?.isAgeMandatory)&&
                                <span style={{color:'red'}}>*</span>}
                                </div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesAgeMatch)}>
                                    {companyMetrics?.age}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_age}
                                  </div>
                                </div>
                              </div>

                              {/* Runway */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Runway
                                {(strategy?.mandatoryPreferences?.isRunwayMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesRunwayMatch)}>
                                    {companyMetrics?.minRunway}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_runway}
                                  </div>
                                </div>
                              </div>

                              {/* Max Tenure */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Max Tenure{(strategy?.mandatoryPreferences?.isTenureMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesTenureMatch)}>
                                    {companyMetrics?.max_tenure}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.max_tenure}
                                  </div>
                                </div>
                              </div>

                              {/* Min Tenure */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Min Tenure
                                {(strategy?.mandatoryPreferences?.isTenureMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesTenureMatch)}>
                                    {companyMetrics?.min_tenure}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_tenure}
                                  </div>
                                </div>
                              </div>

                              {/* Max Roi */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Max Roi
                                {(strategy?.mandatoryPreferences?.isRoiMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesRoiMatch)}>
                                    {companyMetrics?.maxRoi}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.max_roi}
                                  </div>
                                </div>
                              </div>

                              {/* Min Roi */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Min Roi
                                {(strategy?.mandatoryPreferences?.isRoiMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesRoiMatch)}>
                                    {companyMetrics?.roi}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_roi}
                                  </div>
                                </div>
                              </div>

                              {/* Security */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Security
                                {(strategy?.mandatoryPreferences?.isSecurityMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesSecurityMatch)}>
                                    {companyMetrics?.security?.join(
                                      ", "
                                    )}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.security?.join(
                                      ", "
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Loan Type */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Loan Type
                                {(strategy?.mandatoryPreferences?.isLoanTypeMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesLoanTypeMatch)}>
                                    {companyMetrics?.loanType}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.loan_type?.join(
                                      ", "
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Equity Raised */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Equity Raised
                                {(strategy?.mandatoryPreferences?.isEquityRaisedMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesEquityRaisedMatch)}>
                                    {companyMetrics?.equityRaised}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.equityRaised}
                                  </div>
                                </div>
                              </div>

                              {/* Networth */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Networth
                                {(strategy?.mandatoryPreferences?.isNetworthMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesNetWorthMatch)}>
                                    {companyMetrics?.networth}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.networth}
                                  </div>
                                </div>
                              </div>

                              {/* Sector */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Sector
                                {(strategy?.mandatoryPreferences?.isCompanySectorsMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesSectorMatch)}>
                                    {companyMetrics?.sector}
                                  </div>
                                  <div className="w-50 clr16">
                                    /{" "}
                                    {strategy?.desired_company_sectors?.join(
                                      ", "
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* State */}
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Region
                                {(strategy?.mandatoryPreferences?.isGeographyStatesMandatory)&&
                                <span style={{color:'red'}}>*</span>}</div>
                                <div className="dflexJA w-50">
                                  <div className={setColourWithBoolean(strategy?.scoreMatchBoolean?.doesStateMatch)}>
                                    {companyMetrics?.state ?? "Not Present"}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.geography_states?.join(", ")}
                                  </div>
                                </div>
                              </div>
                            </ReactTooltip>
                          ) : (
                            <ReactTooltip
                              key={index}
                              id={`match-percentage-${index}`}
                              type="light"
                              place="bottom"
                              className="Match-tooltip"
                              border={true}
                              borderColor="black"
                              delayHide={500}
                              delayShow={50}
                              delayUpdate={500}
                              multiline={true}
                            >
                              <div className="dflexJA fs14 px-2 py-3">
                                <div className="w-50">Strategy Match</div>
                                <div
                                  className={`w-50 ${getMatchColor(
                                    MATCH_PERCENTAGE
                                  )}`}
                                >
                                  {MATCH_PERCENTAGE}%
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Recur Grade</div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorGreaterThanOrEqualCheck(
                                      companyMetrics?.recur_rating,
                                      strategy?.recur_rating
                                    )}
                                  >
                                    {companyMetrics?.recur_rating}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.recur_rating}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">
                                  Max Investment
                                </div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorLessThanOrEqualCheck(
                                      strategy?.offered_amount,
                                      strategy?.max_investment
                                    )}
                                  >
                                    {strategy?.offered_amount ?? "_"}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.max_investment}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">ARR</div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorGreaterThanOrEqualCheck(
                                      companyMetrics?.min_arr,
                                      strategy?.min_arr
                                    )}
                                  >
                                    {companyMetrics?.min_arr}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {Number(strategy?.min_arr).toFixed(2)}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">ARR Growth</div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorGreaterThanOrEqualCheck(
                                      companyMetrics?.min_arr_growth,
                                      strategy?.min_arr_growth
                                    )}
                                  >
                                    {companyMetrics?.min_arr_growth}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_arr_growth}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Age</div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorGreaterThanOrEqualCheck(
                                      strategy?.age,
                                      strategy?.min_age
                                    )}
                                  >
                                    {strategy?.age}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_age}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Runway</div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorGreaterThanOrEqualCheck(
                                      companyMetrics?.min_runway,
                                      strategy?.min_runway
                                    )}
                                  >
                                    {companyMetrics?.min_runway}
                                  </div>
                                  <div className="w-50 clr16">
                                    / {strategy?.min_runway}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">
                                  Customer Churn
                                </div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorLessThanOrEqualCheck(
                                      companyMetrics?.max_customer_churn,
                                      strategy?.max_customer_churn
                                    )}
                                  >
                                    {getValueWithPercentage(
                                      companyMetrics?.max_customer_churn
                                    )}
                                  </div>
                                  <div className="w-50 clr16">
                                    /{" "}
                                    {getValueWithPercentage(
                                      strategy?.max_customer_churn
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="dflexJA px-2 py-2 border-top fs10">
                                <div className="w-50 color1">Revenue Churn</div>
                                <div className="dflexJA w-50">
                                  <div
                                    className={setColorLessThanOrEqualCheck(
                                      companyMetrics?.max_revenue_churn,
                                      strategy?.max_revenue_churn
                                    )}
                                  >
                                    {getValueWithPercentage(
                                      companyMetrics?.max_revenue_churn
                                    )}
                                  </div>
                                  <div className="w-50 clr16">
                                    /{" "}
                                    {getValueWithPercentage(
                                      strategy?.max_revenue_churn
                                    )}
                                  </div>
                                </div>
                              </div>
                            </ReactTooltip>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {!isNil(strategy.investment_report) ? (
                            <img
                              onClick={() =>
                                downloadReport(strategy?.investment_report)
                              }
                              style={
                                strategy.investment_report
                                  ? { cursor: "pointer" }
                                  : { cursor: "not-allowed" }
                              }
                              src="/assets/download-dv.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              src="/assets/grey_download.svg"
                              alt="grey-download"
                            />
                          )}
                        </td>
                        {isInvestmentReportGenerated && (
                          <td>
                            {isNil(strategy?.investment_report) ? (
                              <label className="mb-0">
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    onFileChangeCapture(
                                      e,
                                      strategy,
                                      "investment_report"
                                    )
                                  }
                                  hidden
                                />
                                <div
                                  className="fs10 dflexJA border1 p-1 fw500"
                                  style={{
                                    border: "1px solid #d2d2d2",
                                    color: "#B8B8B8",
                                    cursor: "pointer",
                                  }}
                                >
                                  <img
                                    style={{ width: "10px", height: "10px" }}
                                    src="/assets/file-upload.svg"
                                    alt=""
                                    className="mr-2 ml-1"
                                  />
                                  <span className="mr-1">Upload</span>
                                </div>
                              </label>
                            ) : (
                              <div
                                className="dflexJA p-1 clr11"
                                style={{
                                  borderRadius: "4px",
                                  backgroundColor: "#F7F7F7",
                                  border: "none",
                                }}
                              >
                                <div
                                  className="text-truncate expand pl-1 fs10 fw300"
                                  style={{
                                    fontFamily: "Gilroy-SemiBold",
                                    wordBreak: "break-all",
                                    width: "55px",
                                  }}
                                >
                                  {last(strategy?.investment_report.split("/"))}
                                </div>
                                <img
                                  onClick={() =>
                                    deleteInvestmentReport(strategy)
                                  }
                                  style={{
                                    width: "12px",
                                    height: "12px",
                                    cursor: "pointer",
                                  }}
                                  src="/assets/clear.svg"
                                  alt=""
                                />
                              </div>
                            )}
                          </td>
                        )}
                        <td>
                          <input
                            key={index}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={
                              isNil(strategy?.offered_amount)
                                ? ""
                                : strategy.offered_amount
                            }
                            disabled={strategy?.offer_status === "Approved"}
                            onChange={
                              strategy?.offer_status !== "Approved"
                                ? (e) =>
                                    handleChange(
                                      e.target.value,
                                      index,
                                      "offered_amount"
                                    )
                                : null
                            }
                            placeholder="Amount"
                            required
                          />
                        </td>
                        <td>
                          <input
                            key={index}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            value={
                              isNil(strategy?.allocated_amount)
                                ? ""
                                : strategy.allocated_amount
                            }
                            disabled={
                              strategy?.offer_status !== "Approved" ||
                              !strategy?.offer_status
                            }
                            onChange={
                              strategy?.offer_status === "Approved"
                                ? (e) => {
                                    handleChange(
                                      e.target.value,
                                      index,
                                      "allocated_amount"
                                    );
                                  }
                                : null
                            }
                            placeholder="Amount"
                            required
                          />
                        </td>
                        <td>
                          {strategy?.offer_status &&
                            strategy?.offer_status !== "Not Sent" && (
                              <div className="switch">
                                <div
                                  onClick={() =>
                                    handleInterest(interest.no, index)
                                  }
                                  className={
                                    "notInterested " +
                                    (strategy?.investor_interested === false
                                      ? "red"
                                      : "")
                                  }
                                >
                                  NO
                                </div>
                                <div
                                  onClick={() =>
                                    handleInterest(interest.yes, index)
                                  }
                                  className={
                                    "interested " +
                                    ((strategy &&
                                      !strategy.investor_interested &&
                                      strategy.investor_interested !== false) ||
                                    strategy?.investor_interested === true
                                      ? "green"
                                      : "")
                                  }
                                >
                                  YES
                                </div>
                              </div>
                            )}
                        </td>
                        <td>
                          {strategy?.offer_status &&
                            strategy?.offer_status !== "Not Sent" && (
                              <div className={rejectionReasonContainer}>
                                {((strategy &&
                                  !strategy.investor_interested &&
                                  strategy.investor_interested !== false) ||
                                  strategy?.investor_interested === true) && (
                                  <div className={disabledSelect}>Select</div>
                                )}
                                {strategy?.investor_interested === false && (
                                  <div>
                                    <div
                                      className={enabledSelect}
                                      onClick={() =>
                                        updateShowRejectionReasonsOptions(index)
                                      }
                                    >
                                      {strategy?.rejection_reason?.length >
                                      0 ? (
                                        <span
                                          className={`text-truncate ${normalText}`}
                                        >
                                          <span
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title={
                                              strategy?.rejection_reason
                                                ?.length === 1
                                                ? strategy?.rejection_reason[0]
                                                : "Multiple Selected"
                                            }
                                          >
                                            {strategy?.rejection_reason
                                              ?.length === 1
                                              ? strategy?.rejection_reason[0]
                                              : "Multiple Selected"}
                                          </span>
                                        </span>
                                      ) : (
                                        <span className={placeholderText}>
                                          Select
                                        </span>
                                      )}
                                      <img
                                        className={
                                          showRejectionReasonOptions === index
                                            ? transformArrow
                                            : null
                                        }
                                        src="/assets/expand-more.svg"
                                        alt="arrow"
                                      />
                                    </div>
                                    {!isEmpty(DEAL_REJECTION_REASONS) &&
                                      showRejectionReasonOptions === index && (
                                        <div className={optionsContainer}>
                                          {Object.keys(
                                            DEAL_REJECTION_REASONS
                                          ).map((key) => (
                                            <div
                                              key={key}
                                              className={singleOptionContainer}
                                              onClick={() =>
                                                handleRejectionReason(
                                                  DEAL_REJECTION_REASONS[key],
                                                  index
                                                )
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                checked={strategy?.rejection_reason?.includes(
                                                  DEAL_REJECTION_REASONS[key]
                                                )}
                                              />
                                              <span>
                                                {DEAL_REJECTION_REASONS[key]}
                                              </span>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                  </div>
                                )}
                              </div>
                            )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {showErrorMessage && (
            <span className="fs10" style={{ color: "#B22727" }}>
              {errorMsgType === "allocationNotFull"
                ? "Deal Should be fully allocated"
                : errorMsgType === "offerAmountChanges"
                ? "Offered amounts can't be greater than the Deal Size"
                : errorMsgType === "allocationAmountChanges"
                ? "Total allocation amount can't be greater than the Deal size"
                : errorMsgType === "allocationGreaterThanOffer"
                ? "Allocation amount must be less than the offered amount"
                : errorMsgType === "negativeValue"
                ? "Can't enter negative value"
                : "Please enter Rejection Reason before saving"}
            </span>
          )}
        </div>
        <div className="border-0 pt-0">
          {isInvestmentReportGenerated ? (
            <div className="dflexJA w-100 bg-white h-100">
              <button
                onClick={handleRegenerateClick}
                disabled={handleBtnState("Re-Generate")}
                className="w-100 btn-link fs12 fw500 py-2 br4 mr-2"
                style={
                  sumOfOffers <= 0
                    ? {
                        backgroundColor: "#d4d4d4",
                        cursor: "not-allowed",
                        border: "1px solid #4C8DFF",
                      }
                    : { border: "1px solid #4C8DFF" }
                }
              >
                Re-Generate Investor Reports
              </button>
              {loanType && loanType?.trim() === LOAN_TYPE.DEBT_SYNDICATION ? (
                <>
                  <button
                    onClick={handleOpenModal}
                    // disabled={handleBtnState("Send Deal")}
                    className="w-100 fs12 fw500 py-2 br4 outlinedbtn mr-2"
                  >
                    <p className={uploadCTAText}>
                      Upload All{" "}
                      <span>{`(${totalUploadedFiles.length} ${
                        totalUploadedFiles.length === 1 || totalUploadedFiles.length === 0
                          ? "file"
                          : "files"
                      } attached)`}</span>
                    </p>
                  </button>
                  <button
                    onClick={checkOfferCreated}
                    disabled={
                      handleBtnState("Send Deal") || sendDealDisableBtn
                    }
                    className="w-100 border-0 fs12 fw500 py-2 br4 app-sub"
                    style={
                      sumOfOffers <= 0
                        ? { backgroundColor: "#d4d4d4", cursor: "not-allowed" }
                        : {}
                    }
                    data-tip=""
                    data-for={"disableMail"}
                  >
                    {`Send ${
                      checkAllDealsStatus ? "Mail" : "Deal"
                    } to ${getTotalNumberOfInvestor()} ${
                      getTotalNumberOfInvestor() === 1
                        ? "Investor"
                        : "Investors"
                    }`}
                  </button>
                  {sendDealDisableBtn && !passedInvestors.length? <ReactTooltip
                    id={`disableMail`}
                    type="light"
                    place="top"
                    className="payout-tooltip"
                    border={true}
                    borderColor="black"
                    delayShow={50}
                    multiline={true}
                  >
                    <div className="tooltip-content">
                    To send mail, please update/upload any file or message
                    </div>
                  </ReactTooltip>:null}
                </>
              ) : (
                <button
                  onClick={checkOfferCreated}
                  disabled={handleBtnState("Send Deal")}
                  className="w-100 border-0 fs12 fw500 py-2 br4 app-sub"
                  style={
                    sumOfOffers <= 0
                      ? { backgroundColor: "#d4d4d4", cursor: "not-allowed" }
                      : {}
                  }
                >
                  Send Deal to Investor
                </button>
              )}
            </div>
          ) : (
            <div className="dflexJA w-100 bg-white h-100">
              <button
                onClick={handleShareDealClick}
                disabled={handleBtnState("Generate Investor")}
                className="w-100 border-0 fs12 fw500 py-2 br4 app-sub mr-2"
                style={
                  sumOfOffers <= 0
                    ? { backgroundColor: "#d4d4d4", cursor: "not-allowed" }
                    : {}
                }
              >
                Generate Investor Reports
              </button>
              {loanType && loanType?.trim() === LOAN_TYPE.DEBT_SYNDICATION ? (
                <>
                  <button
                    onClick={handleOpenModal}
                    // disabled={handleBtnState("Send Deal")}
                    className="w-100 fs12 fw500 py-2 br4 outlinedbtn mr-2"
                  >
                    <p className={uploadCTAText}>
                      Upload All{" "}
                      <span>{`(${totalUploadedFiles.length} ${
                        totalUploadedFiles.length === 1 || totalUploadedFiles.length === 0
                          ? "file"
                          : "files"
                      } attached)`}</span>
                    </p>
                  </button>
                  <button
                    onClick={checkOfferCreated}
                    disabled={
                      handleBtnState("Send Deal") || sendDealDisableBtn
                    }
                    className="w-100 border-0 fs12 fw500 py-2 br4 app-sub"
                    style={
                      sumOfOffers <= 0
                        ? { backgroundColor: "#d4d4d4", cursor: "not-allowed" }
                        : {}
                    }
                    data-tip=""
                    data-for={"disableMail"}
                  >
                    {`Send ${
                      checkAllDealsStatus ? "Mail" : "Deal"
                    } to ${getTotalNumberOfInvestor()} ${
                      getTotalNumberOfInvestor() === 1
                        ? "Investor"
                        : "Investors"
                    }`}
                  </button>
                 {sendDealDisableBtn && !passedInvestors.length ? <ReactTooltip
                    id={`disableMail`}
                    type="light"
                    place="top"
                    className="payout-tooltip"
                    border={true}
                    borderColor="black"
                    delayShow={50}
                    multiline={true}
                  >
                    <div className="tooltip-content">
                    To send mail, please update/upload any file or message
                    </div>
                  </ReactTooltip>:null}
                </>
              ) : (
                <button
                  onClick={checkOfferCreated}
                  disabled={handleBtnState("Send Deal")}
                  className="w-100 border-0 fs12 fw500 py-2 br4 app-sub"
                  style={
                    sumOfOffers <= 0
                      ? { backgroundColor: "#d4d4d4", cursor: "not-allowed" }
                      : {}
                  }
                >
                  Send Deal to Investor
                </button>
              )}
            </div>
          )}
        </div>
        {showPopupMessage && (
          <div className="sanctionNotApprovedModal">
            <div className="popupOverlay">
              <div className="popupMessage">
                <img
                  src="/assets/warning-sign.svg"
                  width="60"
                  height="60"
                  className="warningImg"
                ></img>
                <div className="notSignedLine">
                  Investment Report is not attached.
                </div>
                <div className="continueLine">
                  Do you still want to continue sending the offer?
                </div>
                <button className="cancelBtn" onClick={handlePopupMessage}>
                  Cancel
                </button>
                <button
                  className="continueBtn"
                  onClick={sendDealToInvestorWithoutIR}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        )}
        <Modal
          show={modalOpen}
          onHide={handleCloseModal}
          // size="xl"
          size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ zIndex: "9999" }}
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4 className="modal-title">Send Documents with Investors</h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={UWQueriesContainer}>
              <div ref={querySubmissionBoxRef} className={InputContainer}>
                <textarea
                  ref={textareaRef}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    setUpdatingMsg(e.target.value);
                  }}
                  // onKeyDown={(e) => {
                  //   if (e.key === "Enter" && (e.ctrlKey || e.metaKey))
                  //     onSubmit();
                  // }}
                />
                {!message && (
                  <span
                    className={Placeholder}
                    style={{
                      maxWidth: textareaRef.current?.clientWidth - 30 || "",
                    }}
                    onClick={handlePlaceholderClick}
                  >
                    Enter queries response for this investor{" "}
                    <span>
                      (it’ll be autosave and available throughout this deal)
                    </span>
                  </span>
                )}
                <div className={FileUploadAndShow}>
                  <input
                    type="file"
                    ref={inputFileRef}
                    onChangeCapture={onFileUWChangeCapture}
                    style={{ display: "none" }}
                    accept={getAcceptValue()}
                    multiple
                  />
                  <span
                    className={Attachment}
                    onClick={() => onAttchmentIconClick()}
                  >
                    <img src="/assets/file-attachment.svg" alt="" />
                  </span>
                  <ShowFiles
                    messageType={MESSAGE_TYPES.DRAFT}
                    files={files}
                    onDeleteSuccess={onDeleteSuccess}
                    setFiles={setFiles}
                    setUploadedFiles={setUploadedFiles}
                  />
                </div>
                {fileSizeErrorMsg.length ? (
                  <p className={fileErrorMsg}>{fileSizeErrorMsg}</p>
                ) : null}
                <button
                  className={SubmitButton}
                  onClick={() => onSubmit()}
                  disabled={buttonDisable || isUploading}
                >
                  {isUploading ? "Saving...":"Save"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Drawer>
    </>
  );
}

export default ManageInvestorModal;
